export const loadDB = (loaded) =>{
  fetch(process.env.PUBLIC_URL+"/content/data.json")
    .then(e=>e.text())
    .then(e=>{
      try{
        return JSON.parse(e);
      }
      catch(Ex){
        console.error("Error parsing JSON", e);
      }
      return {};
    })
    .then(e=>{
      loaded && loaded(e);
   });
}


export const loadOffsets = (loaded) =>{
  fetch(process.env.PUBLIC_URL+"/content/offsets.json", {cache: "no-store",headers: {
    'Cache-Control': 'no-cache'
  }})
    .then(e=>e.text())
    .then(e=>{
      try{
        return JSON.parse(e);
      }
      catch(Ex){
        loaded && loaded({});
      }
      return {};
    })
    .then(e=>{
      loaded && loaded(e);
   }).catch(()=>{
      loaded && loaded({});

   });
}

const htmlRegex = /<\/?[a-z][\s\S]*>/i
export function IsHtml(txt){
  return htmlRegex.test(txt);
}



export function downloadObjectAsJson(exportObj, exportName){
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, true, 2));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href",     dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export const ParseBegaProductNumber = (str) =>
	{
		str = str.toUpperCase();
		str = str.replace(/ /g , "");
		var search = str.search(/[A-Z]/);
    const thin = "<span class='thin'> </span>";//"&#8201;"; //&thinsp;
		if(search>=0)
			str = str.slice(0, search) + thin + str.slice(search);
		str = str.substr(0, 2)+thin+str.substr(2);

		return str;
	}

export const ParsePrice = (price) =>
{
  return price;
}