import { useEffect, useState } from 'react';
import './App.css';
import Header from './Header/Header';
import { loadDB, loadOffsets } from './utils/generic';
import { ParseDB } from './_model/DB';
import Floor from './Floor/Floor';
import MiniMap from './MiniMap/MiniMap';
import WallView from './WallView/WallView';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLanguage, setNextWallId, setProductId, setWallId } from './_redux/appSlice';
import ProductView from './ProductView/ProductView';
import { useRef } from 'react';
import Walls from './Walls/Walls';

window.startLocationHash = document.location.hash;

if(window.location.toString().indexOf("middle")>-1){
  window.quality = "m";
}else{
  window.quality = "s";
}

console.log("quality",window.quality);

function App() {
  const [loaded, setLoaded] = useState(null);
  const [size, setSize] = useState({width:window.innerWidth,height:window.innerHeight});
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const keyup = (e) => {

    if(e.key=="Escape"){
      dispatch(setWallId(null));
      dispatch(setNextWallId(null));
      dispatch(setProductId(null));
      navigate("/");
    }else if(e.code === "Space"){
      console.log("debug Space",ref.current.classList.contains("debug"));
      ref.current.classList.toggle("debug");

      if(ref.current.classList.contains("debug"))
        localStorage.setItem("debug","1");
      else
        localStorage.removeItem("debug");
    }
  }

  const resizePageMobile = (e) => {


    window.App_miniMapHeight = window.innerHeight * (80 / 768);
    document.body.style.setProperty('--miniMapHeight',window.App_miniMapHeight+"px")

    window.App_headerHeight = window.innerHeight * (60 / 768);
    document.body.style.setProperty('--headerHeight',window.App_headerHeight+"px")

    setSize({width:window.innerWidth,height:window.innerHeight});
  }

  useEffect(() => {
    loadDB((e)=>{
      window.db = ParseDB(e);
      loadOffsets((offsets)=>{
        window.offsets = offsets;
        for(let wallid in offsets){
          localStorage.setItem("offsets_" + wallid, JSON.stringify(offsets[wallid]));
        }

        console.log("offsets",offsets);
        init();
      })
      //localStorage.getItem("offsets_" + wallId)



    });
    window.addEventListener('resize',resizePageMobile);


    resizePageMobile()


    var doubleTouchStartTimestamp = 0;
    document.body.addEventListener("touchstart", function (event) {
        var now = Date.now();
        if (doubleTouchStartTimestamp + 500 > now) {
            event.preventDefault();
        }
        doubleTouchStartTimestamp = now;
    });

    return () => {
      window.removeEventListener('resize',resizePageMobile);

    }
  },[]);


  const init = () =>{
    setLoaded(Date.now());
    navigate(window.startLocationHash.split("#/").join("/"));
    let hashs = window.startLocationHash.split("/");

    if(hashs.length>1 && hashs[1]!="")
      dispatch(setWallId({id:hashs[1]}));
    if(hashs.length>2 && hashs[2]!="")
      dispatch(setProductId(hashs[2]));
  }

  useEffect(() => {
    if(ref.current && localStorage.getItem("debug") === "1"){
     //ref.current.classList.toggle("debug");
    }
    document.addEventListener("keyup",keyup);
    return () => {
      document.removeEventListener("keyup",keyup);
    }
  },[keyup, ref]);
  useEffect(()=>{
    const queryString = window.location.search;
    const hash = window.location.hash;
    let needle = "";
    if(queryString.indexOf("?lang")>-1){
      needle = queryString;
    }else if(hash.indexOf("?lang")>-1){
      needle = hash;
    }

    if(needle.indexOf("?lang")>-1){
      let parts = needle.split("?lang=");
      if(parts.length>1){
        if(parts[1].length>=2){
          if(parts[1].substring(0,2) === "en"){
            dispatch(setLanguage("en"))
          }else if(parts[1].substring(0,2) === "de"){
            dispatch(setLanguage("de"))
          }else if(parts[1].substring(0,2) === "fr"){
            dispatch(setLanguage("fr"))
          }else if(parts[1].substring(0,2) === "it"){
            dispatch(setLanguage("it"))
          }else if(parts[1].substring(0,2) === "nl"){
            dispatch(setLanguage("nl"))
          }else if(parts[1].substring(0,2) === "es"){
            dispatch(setLanguage("es"))
          }
        }
      }
    }
    console.log(needle);
  },[]);

  if(!window.db  || !loaded)
    return (<div></div>);

  return (
    <div className="App" id="App" ref={ref} style={{'--vh':size.height+"px", '--vw':size.width+"px"}}>
      {window.db && loaded &&<>
      <Header></Header>
      <Walls></Walls>
      {false && <WallView></WallView>}
      <MiniMap></MiniMap>
      <Floor ></Floor>
      <ProductView></ProductView>

      </>}
    </div>
  );
}

export default App;
