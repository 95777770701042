import { createSlice } from '@reduxjs/toolkit'
import { SetLanguage } from '../utils/LanguageString';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    app:null,
    language:"de",

  },
  reducers: {

    setLanguage: (state, action) => {
      state.language = action.payload;
      document.documentElement.setAttribute('lang', state.language);
      SetLanguage(state.language);
    },

    setNextWallId: (state, action) => {
      state.nextWallId = action.payload || null;




    },
    setWallId: (state, action) => {
      state.wallId = action.payload?.id || null;
      if(action.payload?.direction != null)
        state.direction = action.payload?.direction

      let wallIndex = window.db.walls.findIndex((w) => w.id == state.wallId);
      state.wallLeft = state.wallCenter = state.wallRight = null;

      window.db.walls.forEach((wall,index)=>{
          if(index === wallIndex-1)
            state.wallLeft = wall.id
          else if(index === wallIndex)
            state.wallCenter = wall.id
          else if(index === wallIndex+1)
            state.wallRight = wall.id
      })

    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    clickedHome: (state, action) => {
      state.wallId = null;
      state.productId = null;
      state.direction = null;
    },

  },
})
export const {setLanguage, setWallId, setProductId, clickedHome, setNextWallId} = appSlice.actions

export default appSlice.reducer