import { useEffect, useRef, useState } from "react";
import classes from "./Floor.module.css";
import { LanguageString } from "../utils/LanguageString";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setNextWallId, setWallId } from "../_redux/appSlice";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useNavigate } from "react-router-dom";

const floorImg = process.env.PUBLIC_URL + "/content/floorplan.png";
let to_1, ti_1;
let lastClickedId = null;

let lastZoomState = null;
export default function Floor({className="", type="full"}) {
    const wallId = useSelector((state) => state.app.wallId);
    const navigate = useNavigate();
    const [minimized, setMinimized] = useState(type === "mini");
    const [floorImgSize, setFloorImgSize] = useState({ width: 1, height: 1, ratio: 1 });
    const [imgSize, setImgSize] = useState({ width: 1, height: 1, minScale:.1, maxScale:1, parentRatio:1, ratio:1 });
    const activeHotSpotRef = useRef();
    const dispatch = useDispatch();
    const rootRef = useRef();

    const [editableElement, setEditableElement] = useState(null);
    const [offsets, setOffsets] = useState({});


    useEffect(() => {
      var img = new Image();
      img.onload = function () {
          setFloorImgSize({width:img.width, height:img.height});
          updateSize(false, img.width, img.height);
      };
      img.src = floorImg;
    }, []);

    useEffect(() => {
        setTimeout(() => {
          updateSize(false, floorImgSize.width, floorImgSize.height);
          if(floorImgSize.width != 1)
            setTimeout(()=>{
              document.getElementById("floorMap").click("click");
            },100);

        },100);
    }, [floorImgSize]);

    const updateSize = (minimized, width=null, height=null) =>{

      let size = getSize(minimized);

      if(width)
        size.width = width;
      if(height)
        size.height = height;
      setImgSize(size);
    }

    const getSize = (minimized) => {
      var height = floorImgSize.height;
      var width = floorImgSize.width;


      //--miniMapHeight
      const parent = {width:window.innerWidth, height:window.innerHeight - window.App_headerHeight};
      if(minimized){
        parent.height = window.App_miniMapHeight;
        parent.width = parent.height * 1.7;
      }



      let obj = {
        width,
        height,
        ratio: width/height,
        parentWidth:parent.width,
        parentHeight:parent.height,
        parentRatio: parent.width/parent.height,
        maxScale:1
      };
      let minscale = 0;
      if(obj.ratio<obj.parentRatio){
        minscale = obj.parentHeight/obj.height;
      }else{

        minscale = obj.parentWidth/obj.width;
      }
      obj.minScale = minscale;


      return obj;
    };

    const clicked = (wall, zoomToElement, centerView, eventDetail) => {
      clearTimeout(to_1);
      clearInterval(ti_1);

      if(wall){
        lastClickedId = wall.id;
        const realClicked = eventDetail=== 1;

        dispatch(setWallId({id:wall.id, direction:realClicked?"floor":null}));

        navigate("/"+wall.id);
        updateSize();

        to_1 = setTimeout(()=>{
          clearInterval(ti_1);
          zoomToElement(document.getElementById("_"+wall.id), 0.1, 110, "easeOut");
          zoomToElement(document.getElementById("_min_"+wall.id), 0.01, 110, "easeOut")
        }, 10)
      }else{
        dispatch(setWallId(null));

        navigate("/");
        updateSize();

        //console.log("lastZoomState", lastZoomState, zoomToElement);
        if(lastZoomState?.scale && zoomToElement)
        {

          to_1 = setTimeout(()=>{

            zoomToElement(document.getElementById("_"+lastZoomState.id), lastZoomState.scale, 110, "easeOut");
            zoomToElement(document.getElementById("_min_"+lastZoomState.id), lastZoomState.scale, 110, "easeOut")
          }, 10)
        }
        else{
          ti_1 = setInterval(()=>{centerView(getSize(false).minScale, 10, "linear")}, 10);
          to_1 = setTimeout(()=>{clearInterval(ti_1);centerView(getSize(false).minScale,
          310, "easeOut")}, 600)
        }
      }
    }

    useEffect(()=>{
      if(wallId){
        //setMinimized(true);
        if(wallId != lastClickedId && activeHotSpotRef.current){
          document.getElementById("_"+wallId).click();
          document.getElementById("_min_"+wallId).click();
        }
      }else{
        //setMinimized(false);
      }
    },[wallId, floorImgSize])

    const settings = {...imgSize};
    if(minimized){
      settings.minScale = 0.1;
      settings.maxScale = 0.5;
    }

    useEffect(()=>{
      let d = localStorage.getItem("offsets_floor");
      if (d) {
          setOffsets(JSON.parse(d));
      }
    },[])

    useEffect(() => {
      if(minimized || wallId)
        return;

      document.removeEventListener("keydown", keyUpTab);
      document.addEventListener("keydown", keyUpTab);

      return()=>{
          document.removeEventListener("keydown", keyUpTab);
      }
    }, [editableElement]);

    useEffect(() => {
      if (editableElement) {
          document.addEventListener("keyup", keyUp);
      }
      return () => {
        document.removeEventListener("keyup", keyUp);
      };
    }, [editableElement, offsets]);

    useEffect(() => {
      if(minimized || wallId)
        return;
      window.offsets["floor"] = offsets;
    },[offsets]);

    const keyUpTab = (e) =>{
      if(minimized || wallId)
        return;
      //console.log("keyUpTab", e.key);

      if(e.key === "Tab"){
          e.preventDefault();
          e.stopPropagation();

          if(window.db.walls.length > 0){
              if(!editableElement)
                  setEditableElement(window.db.walls[0]);
              else if(window.db.walls.findIndex(p=>p.id === editableElement.id) === window.db.walls.length-1){
                  setEditableElement(window.db.walls[0])
              }else{
                  setEditableElement(window.db.walls[window.db.walls.findIndex(p=>p.id === editableElement.id)+1])
              }
          }

      }
    }

    const keyUp = (e) => {
      if(minimized || wallId)
        return;
      let offset = offsets[editableElement.id];
      //console.log("keyUp", editableElement.id, offset, offsets);
      if (!offset) {
          offset = { top: 0, left: 0};
      }
      let changed = false;
      let stepSize = 5;
      if(e.altKey)
        stepSize = 1;
      else if(e.shiftKey && e.ctrlKey)
        stepSize = 500;
      else if(e.ctrlKey)
        stepSize = 20;
      else if(e.shiftKey)
        stepSize = 100;

      if (e.key === "ArrowRight") {
          offset.left += stepSize;
          changed = true;
      }else if (e.key === "ArrowLeft") {
        offset.left -= stepSize;
        changed = true;
      }else if (e.key === "ArrowUp") {
        offset.top -= stepSize;
        changed = true;
      }else if (e.key === "ArrowDown") {
        offset.top += stepSize;
        changed = true;
      }

      if(changed){
     // console.log("offset", offset);
        setOffsets({ ...offsets, [editableElement.id]: offset });
      }
    };

    const offset = (id) => {
      return offsets[id] || { top: 0, left: 0};
    };

    return (
        <div className={className +" " +classes.root + " " + (minimized ? classes.minimized : "") + " " + (!wallId?"":classes.bigger)} ref={rootRef} style={{'--scale':imgSize.minScale}}>
          {imgSize.width !== 1 &&
            <TransformWrapper
                smooth={true}
                initialScale={settings.minScale}
                minScale={settings.minScale}
                maxScale={settings.maxScale}
                initialPositionX={0}
                initialPositionY={0}
                onZoom={(e)=>rootRef.current.style.setProperty('--scale', e.state.scale)}
            >
                {({ zoomIn, zoomOut, resetTransform, zoomToElement, centerView,   ...rest }) => (
                    <TransformComponent>

                        <div
                            id={"floorMap" + (minimized ? "_mini" : "")}
                            className={classes.map}
                            style={{
                                width: settings.width,
                                height: settings.height,
                            }}
                            onClick={(e)=>{(e.detail === 0 || minimized) && clicked(null, zoomToElement, centerView)}}
                        >
                            <div
                                id="floor_img"
                                className={classes.img}
                                style={{ backgroundImage: `url(${floorImg})` }}
                                onClick={(e)=>{if(e.detail === 0){lastZoomState=null;clicked(null, null, centerView)}}}
                            ></div>
                            <div className={classes.hotspots}>
                              {window.db.walls.map((w,i)=>{
                                return (
                                  <img
                                    key={w.id+"_"+i}
                                    id={(minimized?"_min_":"_")+w.id}
                                    ref={(w.id === wallId ? activeHotSpotRef : null)}
                                    className={classes.btn + " " + (w.id === wallId ? classes.active : "") + " " + (w.id === editableElement?.id ? classes.editable : "")}


                                    onClick={(e)=>{
                                      e.stopPropagation();
                                      if(document.getElementById("App").classList.contains("debug"))
                                        setEditableElement(w);
                                      else{
                                        if(e.detail === 1)
                                          lastZoomState = {
                                            id:w.id,
                                            scale:rest.instance.transformState.scale,
                                            positionX:rest.instance.transformState.positionX,
                                            positionY:rest.instance.transformState.positionY
                                          }
                                        else if(lastZoomState)
                                          lastZoomState.id = w.id

                                        e.target.classList.add(classes.clicked);
                                        setTimeout(() => {
                                          e.target.classList.remove(classes.clicked);
                                        }, 500);
                                        setTimeout(() => {
                                          clicked(w, zoomToElement, centerView, e.detail);;
                                        }, 200);
                                        return;

                                      }
                                    }}
                                    src={process.env.PUBLIC_URL+"/content/"+w.buttonImg}
                                    style={{
                                      left:`calc(${100*w.btnPosition.x/floorImgSize.width}% + ${offset(w.id).left}px)`,
                                      top:`calc(${100*w.btnPosition.y/floorImgSize.height}% + ${offset(w.id).top}px)`

                                    }}>

                                  </img>
                                );
                              })}
                            </div>
                            <div className={classes.selectedAreas}>
                              {window.db.walls.map((w,i)=>{
                                return (
                                  <img
                                    key={w.id+"_"+i}
                                    src={process.env.PUBLIC_URL+"/content/"+w.activeFloorAreaImg}
                                    className={w.id === wallId ? classes.active : ""}
                                  >
                                  </img>
                                );
                              })}
                            </div>
                        </div>
                    </TransformComponent>
                )}
            </TransformWrapper>
            }
        </div>
    );
}
