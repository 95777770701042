import { useState } from 'react';
import classes from './Header.module.css';
import { LanguageString } from '../utils/LanguageString';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, clickedHome as _clickedHome } from '../_redux/appSlice';
import { downloadObjectAsJson } from '../utils/generic';

export default function Header() {
  const [opened, setOpened] = useState(false);
  const language = useSelector((state) => state.app.language);
  const wallId = useSelector((state) => state.app.wallId);
  const dispatch = useDispatch();

  const downloadAllOffsets = ()=>{
    let allOffsets = {};

    window.db.walls.forEach(wall=>{
      let d = localStorage.getItem("offsets_" + wall.id);
      if (d) {
        allOffsets[wall.id] = JSON.parse(d);
      }
    })
    //let d = localStorage.getItem("offsets_" + "floor");

    allOffsets["floor"] = window.offsets["floor"] || {};
    downloadObjectAsJson(allOffsets, "offsets_"+Date.now());
  }

  const clickedHome = () =>{
    if(wallId)
      document.getElementById("floor_img").click()
    else{
      if(!document.fullscreenElement)
        document.body.requestFullscreen()
      else
        document.exitFullscreen()
    }
    //dispatch(_clickedHome());
  }
  const dclickedHome = () =>{
      document.location.reload();
  }


  return (
    <div className={classes.root + " "+ (opened?classes.opened:'')}>
      <div className={classes.bg} onClick={()=>setOpened(false)}></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.8 36.6" width="84px" height="24px" className={classes.logo} onClick={clickedHome} onDoubleClick={dclickedHome}
      ><path d="M21.4,17.1s4.2-2.1,4.6-6c1.1-9.6-8.5-9.7-8.5-9.7H0V35.9H16.4s11.2.5,10.9-10.6C27.2,19.3,21.4,17.1,21.4,17.1ZM6.8,7.1h7.5s5.1-.4,5.2,3.9c.1,3.8-5.2,3.9-5.2,3.9H6.8ZM14.3,30H6.8V20.4h7.5s5.8-.3,5.9,4.8S14.3,30,14.3,30Z" transform="translate(0 0)"></path><polygon points="32.3 35.8 32.3 1.4 57.8 1.4 57.8 7.1 39.5 7.1 39.5 14.3 55.6 14.3 55.6 20 39.5 20 39.5 30.1 57.8 30.1 57.8 35.8 32.3 35.8"></polygon><path d="M76.3,22.8V17.2H90.9V35.8H85.3L85,31.2a11.11,11.11,0,0,1-9.5,5.4c-9,0-16.4-6.9-16.3-18.3C59.2,8.3,65.3,0,75.5,0c7.1,0,13.3,3.5,15.4,12.1h-7c-1.6-4.8-4.5-6.2-8-6.2-6,0-9.5,5.5-9.5,12.4s3.3,12.4,9.5,12.4c4.1,0,7.8-2.9,8.6-7.9Z" transform="translate(0 0)"></path><path d="M112.6,1.4h-7.9L92.5,35.8h7.6l2.5-7.5h12l2.7,7.5h7.5Zm-8.3,21.5s4.4-14.2,4.3-14.3,4.3,14.3,4.3,14.3Z" transform="translate(0 0)"></path></svg>
      <div className={classes.title}>{LanguageString.Get("title")}</div>
      <div className={classes.langBtn} onClick={()=>setOpened(!opened)}>
        <div className={classes.langIcon}  style={{maskImage:`url(${process.env.PUBLIC_URL+"images/icon-language.png"})`}}></div>
      </div>
      <div className={classes.downloadBtn} onClick={()=>downloadAllOffsets()}>Download Offsets</div>
      <div className={classes.langSelect}>
        {
          LanguageString.Languages.map((l,i)=>{
           return <div key={l+"_"+i} className={language === l?classes.active:''} onClick={()=>{dispatch(setLanguage(l));setOpened(false)}}>{LanguageString.Get(l)}</div>
          })
        }
        {false && <div className={classes.fullscreen}>
          {!document.fullscreenElement?
            <>
              Start fullscreen
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" onClick={()=> document.body.requestFullscreen()}>
                <path d="M0 0h36v36h-36z" fill="none"/>
                <path d="M10 21h-3v8h8v-3h-5v-5zm-3-6h3v-5h5v-3h-8v8zm19 11h-5v3h8v-8h-3v5zm-5-19v3h5v5h3v-8h-8z"/>
              </svg>
            </>
          :
          <>
            Exit fullscreen
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"  onClick={()=> document.exitFullscreen()}>
              <path d="M0 0h36v36h-36z" fill="none"/>
              <path d="M7 24h5v5h3v-8h-8v3zm5-12h-5v3h8v-8h-3v5zm9 17h3v-5h5v-3h-8v8zm3-17v-5h-3v8h8v-3h-5z"/>
            </svg>
          </>
        }
      </div>
      }

      </div>
    </div>
  );
}


