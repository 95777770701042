import { useEffect, useRef, useState } from "react";
import classes from "./WallView.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setProductId } from "../_redux/appSlice";


export default function WallView({wall}) {
    const navigate = useNavigate();
    const wallId = useSelector((state) => state.app.wallId);
    const wallRight = useSelector((state) => state.app.wallRight);
    const wallCenter = useSelector((state) => state.app.wallCenter);
    const wallLeft = useSelector((state) => state.app.wallLeft);

    const [imgSize, setImgSize] = useState({
        width: 1,
        height: 1,
        minScale: 0.1,
        maxScale: 1,
        parentRatio: 1,
        ratio: 1,
    });

    const [editableElement, setEditableElement] = useState(null);
    const [offsets, setOffsets] = useState({});

    const dispatch = useDispatch();
    const rootRef = useRef();

    useEffect(() => {
        if (wall) {
            let d = localStorage.getItem("offsets_" + wall.id);
            if (d) {
                setOffsets(JSON.parse(d));
            }
        } else {
            setEditableElement(null);
        }
        return()=>{}
    }, [wall]);

    useEffect(() => {
        if (wall?.id != wallId)
            setEditableElement(null);
    }, [wall, wallId]);


    useEffect(() => {
        if (wall.id === wallId) {
            document.removeEventListener("keydown", keyUpTab);
            document.addEventListener("keydown", keyUpTab);
        }
        return()=>{
            document.removeEventListener("keydown", keyUpTab);
        }
    }, [wall, wallId, editableElement, offsets]);

    useEffect(() => {
        if (wall && rootRef?.current) {


            var img = new Image();
            img.onload = function () {
                var height = img.height;
                var width = img.width;

                const parent = {width:window.innerWidth, height:window.innerHeight - window.App_headerHeight};

                //console.log("parent", parent.height);
                let obj = {
                    width,
                    height,
                    ratio: width / height,
                    parentWidth: parent.width * 0.85,
                    parentHeight: parent.height * 0.9,
                };
                obj.parentRatio = obj.parentWidth / obj.parentHeight;

                if (obj.ratio < obj.parentRatio) {
                    obj.scale = obj.parentHeight / obj.height;
                } else {
                    obj.scale = obj.parentWidth / obj.width;
                }
                setImgSize(obj);
                setEditableElement(null);
            };
            img.src =
                process.env.PUBLIC_URL +
                "/content/walls/" +
                wall.id +
                ".jpg";
        } else {
            setEditableElement(null);
        }
    }, [wall, wallCenter]);

    useEffect(() => {
        if (Object.keys(offsets).length > 0)
            localStorage.setItem("offsets_" + wall.id, JSON.stringify(offsets));
    }, [offsets]);

    useEffect(() => {
        if (editableElement) {
            document.addEventListener("keyup", keyUp);
        }
        return () => {
          document.removeEventListener("keyup", keyUp);
        };
    }, [editableElement, offsets]);




    const keyUpTab = (e) =>{

        if(e.key === "Tab"){
            e.preventDefault();
            e.stopPropagation();

            if(wall?.products.length > 0){
                if(!editableElement)
                    setEditableElement(wall.products[0]);
                else if(wall.products.findIndex(p=>p.id === editableElement.id) === wall.products.length-1){
                    setEditableElement(wall.products[0])
                }else{
                    setEditableElement(wall.products[wall.products.findIndex(p=>p.id === editableElement.id)+1])
                }
            }

        }
    }
    const keyUp = (e) => {
        let offset = offsets[editableElement.id];
        if (!offset) {
            offset = { top: 0, left: 0, width: 0, height: 0 };
        }
        let changed = false;
        let stepSize = 5;
        if(e.altKey)
          stepSize = 1;
        else if(e.ctrlKey)
          stepSize = 20;

        if (e.key === "ArrowRight") {
            if(e.shiftKey)
              offset.width += stepSize;
            else
              offset.left += stepSize;
            changed = true;
        }else if (e.key === "ArrowLeft") {
          if(e.shiftKey)
            offset.width -= stepSize;
          else
            offset.left -= stepSize;
          changed = true;
        }else if (e.key === "ArrowUp") {
          if(e.shiftKey)
              offset.height -= stepSize;
          else
            offset.top -= stepSize;
          changed = true;
        }else if (e.key === "ArrowDown") {
          if(e.shiftKey)
              offset.height += stepSize;
          else
            offset.top += stepSize;
          changed = true;
      }
      if(changed)
        setOffsets({ ...offsets, [editableElement.id]: offset });
    };

    const clicked = (product) => {
        dispatch(setProductId(product.id));
        navigate("/" + wall.id + "/" + product.id);
    };


    const offset = (id) => {
        return offsets[id] || { top: 0, left: 0, width: 0, height: 0 };
    };

    if (!wall) return "";

    //let product = wall.products.find((p) => p.id == productId);
    let posClassName = "";
    if(wall.id === wallLeft)
        posClassName = classes.pleft;
    else if(wall.id === wallCenter)
        posClassName = classes.pcenter;
    else if(wall.id === wallRight)
        posClassName = classes.pright;
    if(posClassName != "")
        posClassName += " " +classes.active;

    if(window.quality === "s" && wall.id != wallCenter)
        return "";
    if(window.quality === "m" && (wall.id != wallCenter && wall.id != wallRight && wall.id != wallLeft))
        return "";
    return (
        <div className={classes.root + " "+posClassName} ref={rootRef} key={wall?.id} id={"WallView_"+wall?.id}>
            <div
                className={classes.map}
                key={wall.id}
                style={{
                    width: imgSize.width,
                    height: imgSize.height,
                    "--scale": imgSize.scale,
                }}
            >
                <div
                    className={classes.img}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}content/walls/${wall.id}.jpg)`,
                    }}
                ></div>
                <div className={classes.hotspots}>
                    {wall.products.map((product, i) => {
                        return (
                            <div
                                key={product.id + "_" + i}
                                onClick={() => clicked(product)}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    setEditableElement(product);
                                }}
                                style={{
                                    left: product.area.x + offset(product.id).left + "px",
                                    top: product.area.y + offset(product.id).top  + "px",
                                    width: product.area.width + offset(product.id).width  + "px",
                                    height: product.area.height + offset(product.id).height  + "px",
                                }}
                                title={product.id}
                                className={
                                    editableElement == product
                                        ? classes.editable
                                        : ""
                                }
                            >
                                {product.id}
                            </div>
                        );
                    })}
                </div>
            </div>

        </div>
    );
}
