import { useEffect, useRef, useState } from "react";
import classes from "./Floor.module.css";
import { LanguageString } from "../utils/LanguageString";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setNextWallId, setWallId } from "../_redux/appSlice";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useNavigate } from "react-router-dom";

const floorImg = process.env.PUBLIC_URL + "/content/floorplan.png";
let to_1, ti_1;
let lastClickedId = null;

let lastZoomState = null;
export default function Floor({className="", type="full"}) {
    const wallId = useSelector((state) => state.app.wallId);
    const nextWallId = useSelector((state) => state.app.nextWallId);

    const navigate = useNavigate();
    const [floorImgSize, setFloorImgSize] = useState({ width: 1, height: 1, ratio: 1 });
    const [imgSize, setImgSize] = useState({ width: 1, height: 1, minScale:.1, maxScale:1, parentRatio:1, ratio:1 });
    const dispatch = useDispatch();
    const rootRef = useRef();

    const [offsets, setOffsets] = useState({});


    useEffect(() => {
      var img = new Image();
      img.onload = function () {
          setFloorImgSize({width:img.width, height:img.height});
          updateSize(false, img.width, img.height);
      };
      img.src = floorImg;
    }, []);

    useEffect(() => {
        setTimeout(() => {
          updateSize(false, floorImgSize.width, floorImgSize.height);
          if(floorImgSize.width != 1)
            setTimeout(()=>{
              //document.getElementById("floorMap").click("click");
            },100);
        },100);
    }, [floorImgSize]);

    const updateSize = (minimized, width=null, height=null) =>{

      let size = getSize(minimized);

      if(width)
        size.width = width;
      if(height)
        size.height = height;
      setImgSize(size);
    }

    const getSize = (minimized) => {
      var height = floorImgSize.height;
      var width = floorImgSize.width;


      //--miniMapHeight
      const parent = {width:window.innerWidth, height:window.innerHeight - window.App_headerHeight};
      if(minimized){
        parent.height = window.App_miniMapHeight;
        parent.width = parent.height * 1.7;
      }



      let obj = {
        width,
        height,
        ratio: width/height,
        parentWidth:parent.width,
        parentHeight:parent.height,
        parentRatio: parent.width/parent.height,
        maxScale:1
      };
      let minscale = 0;
      if(obj.ratio<obj.parentRatio){
        minscale = obj.parentHeight/obj.height;
      }else{

        minscale = obj.parentWidth/obj.width;
      }
      obj.minScale = minscale;


      return obj;
    };

    const clicked = (wall, zoomToElement, centerView, eventDetail) => {
      zoomToElement(document.getElementById("_min_"+wall.id), 0.15, 410, "easeOut")
    }

    useEffect(()=>{
      if(wallId){
        if(wallId != lastClickedId && document.getElementById("_min_"+wallId)){
          document.getElementById("_min_"+wallId).click();
          lastClickedId = wallId;
        }
      }
    },[wallId])

    useEffect(()=>{
      if(nextWallId){
        if(nextWallId != lastClickedId && document.getElementById("_min_"+nextWallId)){
          document.getElementById("_min_"+nextWallId).click();
          lastClickedId = nextWallId;
        }
      }
    },[nextWallId])

    const settings = {...imgSize};

    settings.minScale = 0.1;
    settings.maxScale = 0.5;

    useEffect(()=>{
      let d = localStorage.getItem("offsets_floor");
      if (d) {
          setOffsets(JSON.parse(d));
      }
    },[])

    const goHome = () => {
      dispatch(setWallId(null));
      dispatch(setNextWallId(null));
      navigate("/");
    };
    const offset = (id) => {
      return offsets[id] || { top: 0, left: 0};
    };

    return (
        <div className={className +" " +classes.root + " " + classes.minimized + " " + (!wallId?"":classes.bigger)} ref={rootRef} style={{'--scale':imgSize.minScale}} onTouchStart={goHome} onMouseDown={goHome}>
          {imgSize.width !== 1 &&
            <TransformWrapper
                smooth={true}
                initialScale={settings.minScale}
                minScale={settings.minScale}
                maxScale={settings.maxScale}
                initialPositionX={0}
                initialPositionY={0}
                onZoom={(e)=>rootRef.current.style.setProperty('--scale', e.state.scale)}
            >
                {({ zoomIn, zoomOut, resetTransform, zoomToElement, centerView,   ...rest }) => (
                    <TransformComponent>

                        <div
                            id={"floorMap" + "_mini"}
                            className={classes.map}
                            style={{
                                width: settings.width,
                                height: settings.height,
                            }}
                        >
                            <div
                                id="floor_img_min"
                                className={classes.img}
                                style={{ backgroundImage: `url(${floorImg})` }}
                            ></div>
                            <div className={classes.hotspots}>
                              {window.db.walls.map((w,i)=>{
                                return (
                                  <img
                                    key={w.id+"_"+i}
                                    id={"_min_"+w.id}

                                    className={classes.btn + " " + (w.id === wallId ? classes.active : "")}


                                    onClick={(e)=>{
                                      e.stopPropagation();

                                        if(e.detail === 1)
                                          lastZoomState = {
                                            id:w.id,
                                            scale:rest.instance.transformState.scale,
                                            positionX:rest.instance.transformState.positionX,
                                            positionY:rest.instance.transformState.positionY
                                          }
                                        else if(lastZoomState)
                                          lastZoomState.id = w.id

                                        e.target.classList.add(classes.clicked);
                                        setTimeout(() => {
                                          e.target.classList.remove(classes.clicked);
                                        }, 500);
                                        setTimeout(() => {
                                          clicked(w, zoomToElement, centerView, e.detail);;
                                        }, 200);
                                        return;

                                      }
                                    }
                                    src={process.env.PUBLIC_URL+"/content/"+w.buttonImg}
                                    style={{
                                      left:`calc(${100*w.btnPosition.x/floorImgSize.width}% + ${offset(w.id).left}px)`,
                                      top:`calc(${100*w.btnPosition.y/floorImgSize.height}% + ${offset(w.id).top}px)`

                                    }}>

                                  </img>
                                );
                              })}
                            </div>
                            <div className={classes.selectedAreas}>
                              {window.db.walls.map((w,i)=>{
                                return (
                                  <img
                                    key={w.id+"_"+i}
                                    src={process.env.PUBLIC_URL+"/content/"+w.activeFloorAreaImg}
                                    className={w.id === wallId ? classes.active : ""}
                                  >
                                  </img>
                                );
                              })}
                            </div>
                        </div>
                    </TransformComponent>
                )}
            </TransformWrapper>
            }
        </div>
    );
}
