import { IsHtml } from "./generic";

let language = "de";

export function SetLanguage(lang){
  language = lang;
}

export class LanguageString{
  static __type = 'LanguageString';
  static Languages = ['de', "en", "fr", "it", "es", "nl"];
  static Get = (key, lang=null)=>{

    if(!window.db?.translations)
      return;
    let entry = window.db.translations[key];

    if(!entry)
      return key+"_"+lang;
    return entry.Get(lang);

  }
  constructor(rawObject){
    if(!rawObject){
      return;
    }

    this.entries = {};

    if(typeof(rawObject) === "string"){
      LanguageString.Languages.forEach(l=>{
        this._add(l, rawObject);
      })
    }
    else if(rawObject && (rawObject.__type === LanguageString.__type || rawObject.de || rawObject.en) ){
      LanguageString.Languages.forEach(l=>{
        this._add(l, rawObject[l] || null);
      })


      let _default = null;
      LanguageString.Languages.forEach(l=>{
        if(!_default && !this.entries[l]!==null)
          _default = this.entries[l];
      })

      LanguageString.Languages.forEach(l=>{
        if(this.entries[l]===null)
          this.entries[l] = _default;
      })
    }
  }

  _add(lang, val){
    this.entries[lang] = val;
    if(IsHtml(val))
      this.isHtml = true;
  }


  Get(lang=null){
    if(lang == null)
      lang = language;

    if(!this.entries){
      //console.error("Lang entries not found", this)
      return "";
    }
    let found = this.entries[lang];
    if(found != null)
      return found;

    const keys = Object.keys(this.entries);
    if(keys.length === 0)
      return null;

    if(found == null && keys.length>0)
      found = this.entries[keys[0]];

    if(found == null && keys.length>1)
      found = this.entries[keys[1]];

    return found
  }

  GetWithValues(vals=null, lang=null){
    let str = this.Get(lang);

    if(vals == null)
      return str;

    for(let i in vals){
      str = str.replace("#"+i+"#", vals[i]);
    }


    return str;
  }
}


