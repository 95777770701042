import { LanguageString } from "../utils/LanguageString";

export function ParseDB(raw, rawLang) {
    let db = new DB(raw);

    return db;
}

class DB {
    constructor(raw) {
        this.languages = {};
        this.translations = {};

        LanguageString.languages = Object.keys(raw.languages);
        for (let i in raw.languages) {
          this.languages[i] = new LanguageString(raw.languages[i]);
          this.translations[i] = this.languages[i];
        }

        for (let i in raw.translations) {
          this.translations[i] = new LanguageString(raw.translations[i]);
        }

        this.walls = [];
        for (let i in raw.walls) {
            this.walls.push(new Wall(raw.walls[i]));
        }
    }


}


class Wall {
    constructor(raw) {
        this.id = raw.id;
        this.title = new LanguageString(raw.title);
        this.activeFloorAreaImg = `areas/${this.id}.png`;
        this.buttonImg = `buttons/${this.id}.png`;
        this.subtitle = new LanguageString(raw.subtitle);
        this.btnPosition = raw.btn;
        this.products = [];
        for (let i in raw.products) {
            this.products.push(new Product(raw.products[i]));
        }
    }
}
class Product {
    constructor(raw) {
        this.id = raw.id;
        this.img = raw.img;
        this.title = new LanguageString(raw.title);
        this.subtitle = new LanguageString(raw.subtitle);
        this.area = raw.area || {x:0, y:0, width:100, height:100};
        this.price = raw.price;
        this.url = {
            text: new LanguageString(raw.url.text),
            link: new LanguageString(raw.url.link),
        }
    }
}
