import { createRef, useEffect, useRef, useState } from "react";
import classes from "./Walls.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setProductId, setWallId, setNextWallId } from "../_redux/appSlice";
import { useSwipeable } from "react-swipeable";
import WallView from './../WallView/WallView';

let startX, lastX = 0;

export default function Walls() {
    const navigate = useNavigate();
    const wallId = useSelector((state) => state.app.wallId);
    const nextWallId = useSelector((state) => state.app.nextWallId);

    const direction = useSelector((state) => state.app.direction);
    const productId = useSelector((state) => state.app.productId);

    const dispatch = useDispatch();
    const rootRef = useRef();
    const transRef= useRef();
    const containerRef = useRef();


    const touchstarted = (e) => {
        containerRef.current.dataset.touches = true;
        containerRef.current.classList.add(classes.dragging)
        startX = e.touches[0].clientX;
        lastX = 0;
        document.addEventListener("touchend", touchEnded);
        if(e.touches.length === 1)
            document.addEventListener("touchmove", touchMoved);
    };
    const touchMoved = (e)=>{

        lastX = e.touches[0].clientX - startX;
        //console.log("touchMoved", lastX);
        transRef.current.style.transform = "translateX("+(lastX-window.innerWidth)+"px)";
    }

    const touchEnded = (e) => {
        if(e.touches.length === 0){
            containerRef.current.classList.remove(classes.dragging)
            containerRef.current.dataset.touches = false;

            if(lastX > window.innerWidth*0.1){
                leftClicked();
            }else if(lastX < -window.innerWidth*0.1){
                rightClicked();
            }else{
                transRef.current.style.transform = "translateX("+(-window.innerWidth)+"px)";

            }
        }
        document.removeEventListener("touchmove", touchMoved);
        document.removeEventListener("touchend", touchEnded);

    }

    const leftClicked = () => {
        let index = window.db.walls.findIndex((w) => w.id == wallId);
        rootRef.current.classList.add(classes.disabled)
        transRef.current.style.transform = "translateX("+(0)+"px)";
        //containerRef.current.scrollTo({left:0, behavior:'smooth'})
        let nextIndex = 0;
        if (index > 0)
            nextIndex = index - 1;
        else
            nextIndex = window.db.walls.length - 1;

        dispatch(setNextWallId(window.db.walls[nextIndex].id));
    };
    const rightClicked = () => {
        let index = window.db.walls.findIndex((w) => w.id == wallId);
        rootRef.current.classList.add(classes.disabled)
        //containerRef.current.scrollTo({left:window.innerWidth*2, behavior:'smooth'});
        transRef.current.style.transform = "translateX("+(-window.innerWidth*2)+"px)";
        let nextIndex = 0;
        if (index < window.db.walls.length - 1)
            nextIndex = index + 1;
        else
            nextIndex = 0;

        dispatch(setNextWallId(window.db.walls[nextIndex].id));
    };

    const onscroll = (e) => {
        clearTimeout(window.scrollEndTimer);
        window.scrollEndTimer = setTimeout(()=>{
            //scrollEnded(e);
        }, 100)

    };


    useEffect(() => {
        //console.log("nextWallId", nextWallId, wallId);
        if( nextWallId != null && nextWallId != wallId){
            setTimeout(()=>{
                containerRef.current.classList.add(classes.dragging)
                rootRef.current.classList.remove(classes.disabled)
                dispatch(setWallId({id:nextWallId}))
                navigate("/" + nextWallId);
                setTimeout(()=>{ containerRef.current.classList.remove(classes.dragging)}, 100)
            }, 400)
        }
    },[nextWallId, wallId, setWallId]);


    useEffect(() => {
        var catcher = function(evt) {
            if (evt.touches.length >= 2)
                evt.preventDefault();
        };

        if (containerRef.current){
            //containerRef.current.scrollTo({left:window.innerWidth, behavior:'instant'});
            transRef.current.style.transform = "translateX("+(-window.innerWidth)+"px)";
            containerRef.current.addEventListener('touchstart', catcher, true);
        }

        return () => {
            if (containerRef.current){
                containerRef.current.removeEventListener('touchstart', catcher);
            }
        }
    }, [wallId, containerRef.current]);

    const isFirst = false;//window.db.walls.findIndex((w) => w.id == wallId) === 0;
    const isLast = false; //window.db.walls.findIndex((w) => w.id == wallId) === window.db.

    if (!window.db?.walls) {
        return "...loading";
    }
    //let product = wall.products.find((p) => p.id == productId);

    const lastWall = window.db.walls[window.db.walls.length - 1];
    const firstWall = window.db.walls[0];
    return (
        <div className={classes.root + " "+ (!wallId?classes.hidden:'')} ref={rootRef}>
            <div className={classes.containerScroll}  ref={containerRef} onScroll={onscroll} onTouchStart={touchstarted} >
                <div
                    className={classes.container + " " + classes[direction]}
                    ref={transRef}
                >
                    {window.db.walls.map((wall) => {
                        return (
                        <WallView wall={wall} key={wall.id}></WallView>
                        );
                    })}

                </div>
            </div>
            {!isFirst && (
                <img
                    className={classes.arrow + " " + classes.left}
                    src={`${process.env.PUBLIC_URL}images/icon-chevronLeft.png`}
                    alt="left"
                    onClick={leftClicked}
                />
            )}
            {!isLast && (
                <img
                    className={classes.arrow + " " + classes.right}
                    src={`${process.env.PUBLIC_URL}images/icon-chevronRight.png`}
                    alt="right"
                    onClick={rightClicked}
                />
            )}
        </div>
    );
}
