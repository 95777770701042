import { useEffect, useRef, useState } from "react";
import classes from "./MiniMap.module.css";
import { LanguageString } from "../utils/LanguageString";
import { useSelector } from "react-redux";
import Floor from "../Floor/Floor";
import FloorMin from "../Floor/FloorMin";


export default function MiniMap() {
    const wallId = useSelector((state) => state.app.wallId);
    const language = useSelector((state) => state.app.language);

    const formatString = (str) => {
      let parts = str.split("#");

      if(parts?.length>2 && parts.length%2===1){
        let output = str;
        let values = [];
        for(let i=1;i<parts.length;i+=2){

          values.push(parts[i]);
        }
        for(let i in values){
          if(values[i] === "id")
            output = output.replaceAll("#"+values[i]+"#", wall.id);
          else
            output = output.replaceAll("#"+values[i]+"#", LanguageString.Get(values[i]));
        }


        return output;
      }

      return str;
    }


    const wall = window.db.walls.find((w)=>w.id==wallId);
    return (
        <div className={classes.root + " " + (wall ? classes.visible : "")}>
          <FloorMin class={classes.floor} type="mini" ></FloorMin>
          <h1>{wall && formatString(wall.title.Get())}</h1>
          <h2>{wall && formatString(wall.subtitle.Get())}</h2>
        </div>
    );
}
