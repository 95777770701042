import { useEffect, useRef, useState } from "react";
import classes from "./ProductView.module.css";
import { LanguageString } from "../utils/LanguageString";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setProductId } from "../_redux/appSlice";
import { useNavigate } from "react-router-dom";
import { ParseBegaProductNumber, ParsePrice } from "../utils/generic";

export default function ProductView() {
    const wallId = useSelector((state) => state.app.wallId);
    const productId = useSelector((state) => state.app.productId);
    const language = useSelector((state) => state.app.language);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const wall = window.db.walls.find((w) => w.id == wallId);
    const product = wall?.products.find((p) => p.id == productId);
    const close= ()=>{
      dispatch(setProductId(null));
      navigate("/" + wall.id);
    }
    const openUrl = ()=>{
      window.open(product.url.link.Get(), '_blank').focus();
    }




    if (!product) return "";

    return (
        <div className={classes.root}>
            <div className={classes.bg} onClick={close}></div>
            <div className={classes.popup}>
                <div className={classes.window}>
                    <div className={classes.left} style={{backgroundImage:`url(${process.env.PUBLIC_URL+"content/"+product.img})`}}></div>
                    <div className={classes.right}>
                        <div className={classes.r_top}>
                          <p className={classes.id} dangerouslySetInnerHTML={{__html:ParseBegaProductNumber(product.id)}}>

                          </p>
                          <br />
                          <p className={classes.title}>{product.title.Get()}</p>
                          <p className={classes.price}>{ParsePrice(product.price)}</p>
                        </div>
                        <div className={classes.r_bottom} onClick={()=>{
                          openUrl()}}>
                          <div className={classes.linkIcon}  style={{backgroundImage:`url(${process.env.PUBLIC_URL+"images/icon-link.png"})`}}></div>
                          <p>{product.url?.text.Get() || (window.db.translations.product_url_text.Get())}</p>
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <div
                        className={classes.close}
                        onClick={close}
                    >
                        <div className={classes.closeIcon} style={{backgroundImage:`url(${process.env.PUBLIC_URL+"images/icon-close.png"})`}}></div>
                        {false && <p>{LanguageString.Get("close")}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}
